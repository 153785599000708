.tree-surgery-main{
    &__container{
        position: relative;
    }

    &__tree{
        position: absolute;
        right: -1em;
        bottom: 0;
        @media only screen and (max-width: 1199px) {
            width: 35vw;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
}
/* ============================= */
/* ============================= */
.tree-prunning{
    background-color: $gray;
    border-radius: 5px;
    margin-top: 3em;
}
/* ============================= */
/* ============================= */

.stump-removal{
    &__image{
        height: 100%;
        object-fit: cover;
    }
}