.hero {
    position: relative;
    overflow: hidden;
    margin-top: -28px;
    @media #{$navBreak} {
        margin-top: 0;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &__content {
        position: relative;
        width: 600px;
        padding-top: 150px;
        padding-bottom: 150px;
        color: $light;

        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
}
