.hedge-trimming {
    &__row {
        align-items: center;
        background: linear-gradient(to right, $white 40%, $gray 60%);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 0;
        @media only screen and (max-width: 991px) {
            align-items: initial;
        }
        @media only screen and (max-width: 767px) {
            background: transparent;
        }
    }

    &__col {
        img {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            @media only screen and (max-width: 767px) {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 0;
            }
        }

        &-inner {
            @media only screen and (max-width: 767px) {
                background-color: $gray;
                padding: 1em;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
