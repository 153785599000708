.services {
    margin-top: -50px;
    position: relative;
    overflow: hidden;

    &__item {
        padding-top: 50px;
        background-color: $white;
        border-radius: 5px;
    }

    &__icon {
        background-color: $gray;
        width: 106px;
        height: 106px;
        border-radius: 50%;
        margin: 0 auto;
        padding: 1em;
        display: flex;

        &-img {
            width: 60px;
            margin: auto;
        }
    }

    &__content {
        text-align: center;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 18px;
            margin: 1.2em 0;
        }
    }

    &__swiper {
        &-arrow {
            top: 55%;
            color: $primary;
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            &:after {
                font-size: 2em;
            }
        }
    }

    &__tree{
        position: absolute;
        right: -288px;
        bottom: -10px;
        @media only screen and (max-width: 1490px) {
            width: 40vw;
        }
        @media only screen and (max-width: 1199px) {
            display: none;        
        }
    }
}

/* ---------------------------------------------------------------- */

.home-about {
    background-color: $gray;
    border-radius: 5px;
    padding: 60px;
    @media only screen and (max-width: 575px) {
        padding: 1em;
    }

    &__row {
        align-items: flex-end;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 36;
        @media only screen and (max-width: 575px) {
            font-size: 30px;
        }
    }

    ul {
        font-size: 22px;
        font-weight: 500;
        list-style: none;
        @media only screen and (max-width: 991px) {
            margin: 0;
            padding: 1em;
            font-size: 1em;
        }
        li:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 27px;
            background-image: url("../img/icons/leaf.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 0.5em;
            @media only screen and (max-width: 991px) {
                width: 15px;
                height: 17px;
            }
        }

        li + li {
            margin-top: 0.6em;
        }
    }
}
