.nav {
    background-color: $primary;
    border-radius: 5px;
    @media #{$navBreak} {
        padding-top: 0;
        padding-bottom: 0;
    }

    &bar-nav {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &-link{
        color: $light;
        transition: all 100ms ease-in-out;

        &.active, &:hover, &:focus{
            color: $light;
            font-weight: 600;
        }
    }
}
