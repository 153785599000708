$navBreak: "only screen and (max-width : 991px)";

.header {
  position: relative;
  z-index: 999;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }

  &__tel {
    color: $dark;
    font-size: 0.9em;
    font-weight: 600;
    transition: color 200ms ease-in-out;
    @media #{$navBreak} {
      display: none;
    }
    span {
      color: $primary;
      font-size: 1em;
      font-weight: 400;
      transition: color 200ms ease-in-out;
    }

    & + & {
      margin-left: 2em;
    }

    &:hover {
      text-decoration: none;
      color: $primary;

      span {
        color: $dark;
      }
    }
  }

  &__toggler {
    display: none;
    @media #{$navBreak} {
      display: block;
    }
  }
  @media #{$navBreak} {
    &__nav-container {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.navbar-toggler {
  background: none;
  border: none;
  &:active,
  &:focus {
    outline: 0;
  }
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
    background-color: $dark;
  }
}

.navbar-toggler {
  .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(0);
    }
    &:nth-of-type(2) {
      opacity: 1;
      filter: alpha(opacity = 100);
      width: 100%;
    }
    &:nth-of-type(3) {
      transform: rotate(0);
      width: 100%;
    }
  }
  &[aria-expanded="true"] .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity = 0);
      width: 100%;
    }
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
      width: 100%;
    }
  }
}
