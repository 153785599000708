.footer {
    background-color: $dark;

    // &__content{
    //     &-col{
    //         display: flex;
    //     }
    // }

    &__list {
        list-style: none;
        &-item {
            & + & {
                margin-top: 0.5em;
            }

            &-link {
                position: relative;
                color: $light;
                transition: all 200ms ease-in-out;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background-color: $primary;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 200ms ease-in-out;
                }
                
                &:hover{
                    color: $primary;
                    text-decoration: none;
                    &::before{
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }
    }

    &__copyright{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 1em;
            left: 1em;
            height: 1px;
            background-color: darken($light, 80%);
        }
    }
}

.socialMedia {
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 200px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $light;
        overflow: hidden;
        transition: all 200ms ease-in-out;

        &.youtube:hover {
            background-color: #d32e2a;
        }

        &.twitter:hover {
            background-color: #1da1f2;
        }

        &.facebook:hover {
            background-color: #3b5998;
        }

        &.instagram {
            position: relative;
            overflow: hidden;
            background-color: transparent;
            svg {
                z-index: 4;
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition: opacity 200ms ease-in-out;
            }
            &::before {
                background: #d6249f;
                background: radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
            }
            &::after {
                background: $light;
            }
            &:hover::after {
                opacity: 0;
                transition: opacity 200ms ease-in-out;
            }
        }

        &.phone:hover {
            border-color: #01c476;
            background: #01c476;
        }

        svg {
            width: 20px;
            height: 20px;

            .icon {
                fill: $primary;
            }
        }

        &:hover {
            svg .icon {
                fill: $light;
            }
        }
    }
}
