.contact-info{
  position: relative;

  &__link{
    color: $dark;
    font-weight: 500;

    &:hover{
      color: $primary;
    }
  }

  .services__tree{
    bottom: -7px;
  }
}

.contact-form{
  background-color: $gray;
  padding: 2em;

  .form-control{
    border-radius: 20px;
  }

  a{
    color: $primary;
    &:hover{
      color: $primary;
    }
  }
}