html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: $dark;

  &.noScroll {
    overflow: hidden;
    height: 100%;
  }
}

footer {
  margin-top: auto;
}

/* HEADING */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
  font-weight: 400;
  margin-bottom: 1rem;
}

.playball {
  font-family: "Playball", cursive;
  font-size: 32px;
  color: $primary;
  margin-bottom: 0;
  @media only screen and (max-width: 575px) {
    font-size: 20px;
  }
}

.big-heading{
  font-size: 48px;
}

/* BUTTONS */
.btn {
  border: 0;
  min-width: 170px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  font-weight: 500;
  &-primary {
    background-color: $primary;
    &:hover {
      background-color: $dark;
    }
  }
}

/* THE CONTENT */
.theContent {
  background-color: $gray;
  padding: 2em;
  margin-top: 2em;
  border-radius: 5px;
  // box-shadow: 0 0 10px rgba($dark, 0.3);
}
